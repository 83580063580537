<style lang="less" scoped>
</style>

<template>
    <router-view></router-view>
</template>

<script>
export default {
  name: "Commodiy",
  methods: {}
};
</script>